<template>
  <ProductsList />
</template>

<script>
  export default {
    components: {
      ProductsList: () => import('@/components/comercial/products/ProductsList')
    }
  }
</script>